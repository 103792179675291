@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@tailwind variants;

/* Base styling */
@layer base {
    body {
        @apply text-[14px] relative scroll-smooth font-normal leading-[1.4] bg-[#E5E5E5] text-black xxl:max-w-[2000px] mx-auto;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Poppins', sans-serif;
    }
}

html, body, #app, #app>div {
    height: 100%;
    width: 100%;
    min-width: 100vw;
    background-color: #262626;
  }

  .tooltip {
    @apply invisible absolute;
  }
  
  .has-tooltip:hover .tooltip {
    @apply visible z-50;
  }
  
/* Add components styling */
@layer components {

    .container-fluid {
        --gutter-x: 1.5rem;
        --gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--gutter-x) * .5);
        padding-left: calc(var(--gutter-x) * .5);
        margin-right: auto;
        margin-left: auto;
    }

    .btn {
        @apply bg-theme inline-block text-white rounded tracking-wider px-6 py-2 font-extrabold outline-0 focus:outline-0 hover:bg-opacity-90 transition-colors;
    }
    .btn-dashed-under {
        @apply relative z-0 transition-all;
    }
    .btn-dashed-under:hover::before {
        content: '';
        @apply absolute bottom-[5px] lg:bottom-[10px] left-0 right-0 w-full h-full bg-[#16DB93] max-h-[7px] z-[-1];
    }

    .dashed-under {
        @apply relative z-0 transition-all;
    }

    .dashed-under:hover::before {
        content: '';
        @apply absolute bottom-[3px] left-0 right-0 inline-block h-full bg-[#BFFFBC] max-h-[4px] z-[-1];
    }

    .bg-overlay::before {
        content: '';
        @apply absolute inset-0 w-full h-full bg-black/50 z-[2];
    }


    .col-form-label {
        @apply block font-normal text-[12px] pl-2;
    }

    .form-control, .form-select, .form-textarea {
        @apply bg-white text-black rounded-[10px] w-full min-h-[45px] border-white outline-0 focus:outline-0 px-4 text-lg;
    }

    .form-checkbox {
        @apply w-[20px] h-[20px] inline-block bg-gray-300;
    }
    .form-checkbox-label {
        @apply w-[calc(100%-20px)] ml-5 text-sm font-medium inline-block;
    }

    .form-textarea {
        @apply py-3;
    }
    .form-textarea::placeholder {
        @apply text-[14px] text-[#FCFCFC];
    }
}